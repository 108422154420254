#rewards
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center



    .hr
        height: 1px
        width: 100%
        margin: 32px auto
        max-width: 700px
        background: rgba(255, 255, 255, 0.2)

    .referral-sub-header
        font-size: 14px
        text-align: center
        // font-weight: 600
        margin: 0 auto 24px
        max-width: 500px

        span
            color: var(--highlightFontColor)
            font-weight: bold

    .referral-copy
        display: flex
        margin-bottom: 24px
        align-items: center
        width: 100%
        justify-content: center

    button
        margin-left: auto
        margin-right: auto
        // width: 50%
        padding: 4px 30px
        font-weight: bold
        border: 1px solid var(--highlightFontColor)
        background-color: rgba(0, 0, 0, 0.1)
        color: var(--highlightFontColor)

    .referral-code-box
        display: flex
        justify-content: space-between
        font-weight: 600
        font-size: 16px
        cursor: pointer
        margin-left: 10px
        border: 1px solid rgba(255, 255, 255, 0.3)
        color: #fff
        border-radius: 4px
        // width: 20%
        padding: 8px 0 8px 15px

        .font
            padding: 0 15px

    .referral-social
        display: flex
        align-items: center
        width: 20%
        justify-content: space-between

    #loyalists-table
        width: 100%
